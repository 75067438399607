@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?9d5y5t');
  src:  url('fonts/icomoon.eot?9d5y5t#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?9d5y5t') format('truetype'),
    url('fonts/icomoon.woff?9d5y5t') format('woff'),
    url('fonts/icomoon.svg?9d5y5t#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

i {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.bookriver-icon-StarsIcon:before {
  content: "\e934";
}
.bookriver-icon-LibraryIcon:before {
  content: "\e91d";
}
.bookriver-icon-SecondArrowIcon:before {
  content: "\e933";
}
.bookriver-icon-NotificationIcon:before {
  content: "\e932";
}
.bookriver-icon-YandexIcon:before {
  content: "\e900";
}
.bookriver-icon-GoogleIcon .path1:before {
  content: "\e901";
  color: rgb(251, 187, 0);
}
.bookriver-icon-GoogleIcon .path2:before {
  content: "\e902";
  margin-left: -1em;
  color: rgb(81, 142, 248);
}
.bookriver-icon-GoogleIcon .path3:before {
  content: "\e903";
  margin-left: -1em;
  color: rgb(40, 180, 70);
}
.bookriver-icon-GoogleIcon .path4:before {
  content: "\e904";
  margin-left: -1em;
  color: rgb(241, 67, 54);
}
.bookriver-icon-OpenBookIcon:before {
  content: "\e905";
}
.bookriver-icon-AddIcon:before {
  content: "\e906";
}
.bookriver-icon-AdvertIcon:before {
  content: "\e907";
}
.bookriver-icon-AppleIcon:before {
  content: "\e908";
}
.bookriver-icon-ArrowIcon:before {
  content: "\e909";
}
.bookriver-icon-BookmarkIcon:before {
  content: "\e90a";
}
.bookriver-icon-BooksIcon:before {
  content: "\e90b";
}
.bookriver-icon-BookWithHeartIcon:before {
  content: "\e90c";
}
.bookriver-icon-BullhornIcon:before {
  content: "\e90d";
}
.bookriver-icon-CheckIcon:before {
  content: "\e90e";
}
.bookriver-icon-ClockIcon:before {
  content: "\e90f";
}
.bookriver-icon-CloseIcon:before {
  content: "\e910";
}
.bookriver-icon-CommentsIcon:before {
  content: "\e911";
}
.bookriver-icon-DotsIcon:before {
  content: "\e912";
}
.bookriver-icon-DownloadIcon:before {
  content: "\e913";
}
.bookriver-icon-EyeIcon:before {
  content: "\e914";
}
.bookriver-icon-FilledBookmarkIcon:before {
  content: "\e915";
}
.bookriver-icon-FilledBooksIcon:before {
  content: "\e916";
}
.bookriver-icon-FilledReadersIcon:before {
  content: "\e917";
}
.bookriver-icon-FilledStarIcon:before {
  content: "\e918";
}
.bookriver-icon-FullscreenIcon:before {
  content: "\e919";
}
.bookriver-icon-GenreSortGridIcon:before {
  content: "\e91a";
}
.bookriver-icon-GiftIcon:before {
  content: "\e91b";
}
.bookriver-icon-HideEyeIcon:before {
  content: "\e91c";
}
.bookriver-icon-ListIcon:before {
  content: "\e91e";
}
.bookriver-icon-LockIcon:before {
  content: "\e91f";
}
.bookriver-icon-MailIcon:before {
  content: "\e920";
}
.bookriver-icon-MastercardIcon:before {
  content: "\e921";
}
.bookriver-icon-MoveIcon:before {
  content: "\e922";
}
.bookriver-icon-OkIcon:before {
  content: "\e923";
}
.bookriver-icon-PencilIcon:before {
  content: "\e924";
}
.bookriver-icon-PenIcon:before {
  content: "\e925";
}
.bookriver-icon-QuestionPromptIcon:before {
  content: "\e926";
}
.bookriver-icon-ReadersIcon:before {
  content: "\e927";
}
.bookriver-icon-SearchIcon:before {
  content: "\e928";
}
.bookriver-icon-SecondPencilIcon:before {
  content: "\e929";
}
.bookriver-icon-SettingsReaderIcon:before {
  content: "\e92a";
}
.bookriver-icon-ShareIcon:before {
  content: "\e92b";
}
.bookriver-icon-StarIcon:before {
  content: "\e92c";
}
.bookriver-icon-TrashIcon:before {
  content: "\e92d";
}
.bookriver-icon-UploadFile:before {
  content: "\e92e";
}
.bookriver-icon-VisaIcon:before {
  content: "\e92f";
}
.bookriver-icon-VkIcon:before {
  content: "\e930";
}
.bookriver-icon-WarningPromptIcon:before {
  content: "\e931";
}
