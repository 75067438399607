/* тут будут подключаться шрифты, задаваться стили для body, описываться css-переменные и супер глобальные классы
 типа .container*/
@import './public/svg-sprites/style';

@font-face {
  font-family: "Merriweather";
  font-weight: 300;
  font-style: normal;
  font-display: swap;

  src: url("/fonts/Merriweather-Light.ttf") format("truetype");
}

@font-face {
  font-family: "Merriweather";
  font-weight: 400;
  font-style: normal;
  font-display: swap;

  src: url("/fonts/Merriweather-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Merriweather";
  font-weight: 700;
  font-style: normal;
  font-display: swap;

  src: url("/fonts/Merriweather-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Merriweather";
  font-weight: 900;
  font-style: normal;
  font-display: block;

  src: url("/fonts/Merriweather-Black.ttf") format("truetype");
}

@font-face {
  font-family: "Gilroy";
  font-weight: 400;
  font-style: normal;
  font-display: swap;

  src: url("/fonts/Gilroy-Regular.woff") format("woff");
}

@font-face {
  font-family: "Gilroy";
  font-weight: 500;
  font-style: normal;
  font-display: swap;

  src: url("/fonts/Gilroy-Medium.woff") format("woff");
}

@font-face {
  font-family: "Gilroy";
  font-weight: 600;
  font-style: normal;
  font-display: swap;

  src: url("/fonts/Gilroy-SemiBold.woff") format("woff");
}

@font-face {
  font-family: "Gilroy";
  font-weight: 800;
  font-style: normal;
  font-display: swap;

  src: url("/fonts/Gilroy-ExtraBold.woff") format("woff");
}

@font-face {
  font-family: "Roboto";
  font-weight: 400;
  font-style: normal;
  font-display: swap;

  src: url("/fonts/Roboto-Regular.woff2") format("woff2"),
  url("/fonts/Roboto-Regular.woff") format("woff");
}

@font-face {
  font-family: "Roboto";
  font-weight: 500;
  font-style: normal;
  font-display: swap;

  src: url("/fonts/Roboto-Medium.woff2") format("woff2"),
  url("/fonts/Roboto-Medium.woff") format("woff");
}

@font-face {
  font-family: "Roboto";
  font-weight: 700;
  font-style: normal;
  font-display: swap;

  src: url("/fonts/Roboto-Bold.woff") format("woff");
}

@font-face {
  font-family: "Circe";
  font-weight: normal;
  font-style: normal;
  font-display: swap;

  src: url("/fonts/Circe/Circe-Regular.woff") format("woff"),
  url("/fonts/Verdana/Circe-Regular.woff.ttf") format("truetype"),
  url("/fonts/Verdana/Circe-Regular.woff.woff2") format("woff2");
}


:root {
  /*colors*/
  --primary-color: #1d78fc;
  --primary-hover-color: #2f80ed;
  --black-color: #282c2f;
  --ligth-black-color: #2f3942;
  --gray-color: #7f8794;
  --gray2-color: #e8e8e8;
  --gray3-color: #d0d9dd;
  --gray4-color: #eeeeee;
  --gray5-color: #c4c4c4;
  --gray6-color: #F2F2F2;
  --yellow-color: #ffedb8;
  --yellow-dark-color: #F8B814;
  --green-color: #b9f8d3;
  --bg-green-color: #eafdf2;
  --green2-color: #219653;
  --green3-color: #28B446;
  --error-color: #ea1b4d;
  --background-error-color: rgba(234, 27, 77, 0.05);
  --bg-color: #f9f9f9;
  --white-color: #ffffff;
  --border-color: #ebebeb;
  --warning-color: #fff9e6;
  --blue-color: #d2f4ff;
  --sandy-color: #F8EBCA;
  --ligth-sandy-color: #FDF5DF;
  --ok-color: #f27220;
  --vk-color: #43668a;
  /*sizes*/
  --container-width: 1200px;
  --image-aspect: 147%; // 0,68

  /*fonts*/
  --main-font: "Gilroy", arial, sans-serif;
  --second-font: "Roboto", arial, sans-serif;
  --landing-main-font: "Merriweather", serif;
  --landing-font: "Circe", serif;

  // components
  --header-height: 86px;
}

*,
:after,
:before {
  box-sizing: border-box;
}

body {
  margin: 0;
  background: var(--bg-color) !important;
  overflow-x: hidden;
  font-variant: tabular-nums;
  font-feature-settings: "tnum";
  line-height: 1.5715;
  background-color: #fff;
  font-size: 14px;
}

.ant-checkbox {
  transform: translate(0, 0);
  align-self: center;
}

pre {
  margin: 0;
}

* {
  font-family: var(--main-font);
}

a {
  text-decoration: none;
  transition: color 0.3s;
  color: #1890ff;

  &:hover {
    color: #40a9ff;
  }
}

p {
  margin-bottom: 0;
}

* {
  scrollbar-width: thin;
  scrollbar-color: #ADABAB transparent;
}

/* Works on Chrome/Edge/Safari */
*::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

*::-webkit-scrollbar-track {
  background: transparent;
}

*::-webkit-scrollbar-thumb {
  background-color: #ADABAB;
  border-radius: 25px;
  border: 3px solid transparent;
}

#__next {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

// options-popover component
.options-popover__overlay {
  .ant-popover-inner-content {
    padding: 0 !important;
  }

  .ant-popover-inner {
    box-shadow: none;
  }
}

ul {
  padding: 0;
  margin: 0;
  list-style: none;
}
